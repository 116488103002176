<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h3"> Enter Your Verification Code </v-card-title>
        <v-card-text>Enter the Verification Code From your Email</v-card-text>
        <v-card-title class="text-h3"> Verification Code : </v-card-title>
        <v-card-text>
          <v-text-field v-model="code" outlined :rules="[rules.required]" required color="#ffde7b" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#3f51b5" class="white--text" @click="onVerifyCode" :loading="loading"> Verify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
import router from '@/router'
import moment from 'moment'
import cnst from '@/const/const'
export default {
  props: {
    value: Boolean,
    mail: String,
  },
  data() {
    return {
      code: '',
      rules: {
        required: (value) => !!value || '必須入力です',
      },
      loading: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions('login', ['verifyCode']),
    ...mapActions('stamp', ['getStamp']),
    onVerifyCode() {
      this.loading = true
      const input = {
        mail: window.localStorage.getItem(cnst.STORAGE_EMAIL),
        verify_code: this.code,
        device_token: !!window.localStorage.getItem(cnst.STORAGE_DEVICE_TOKEN) ? window.localStorage.getItem(cnst.STORAGE_DEVICE_TOKEN) : '',
        auth_token: !!localStorage.getItem(cnst.STORAGE_AUTHTOKEN) ? localStorage.getItem(cnst.STORAGE_AUTHTOKEN) : '',
      }
      this.verifyCode(input)
        .then((res2) => {
          if (res2.status === 200) {
            // this.showVerifyCode = true
            localStorage.setItem(cnst.STORAGE_AUTHTOKEN, res2.data.auth_token)
            this.checkStamp()
          }
        })
        .catch((err) => {
          console.log(err)
          // this.loader = null
          this.loading = false
        })
    },
    async checkStamp() {
      const input = {
        mail: localStorage.getItem(cnst.STORAGE_EMAIL),
      }
      await this.getStamp(input)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.last_time != undefined) {
              let lasttime = moment(moment(res.data.last_time).format('YYYY-MM-DD'))
              if (moment(moment().format('YYYY-MM-DD')).diff(lasttime, 'days') == 0) {
                if (this.$route.path !== '/home') router.push({ path: '/home' })
                this.showLayout = true
              } else {
                if (this.$route.path !== '/stamp') router.push({ path: '/stamp' })
                this.showLayout = true
              }
            } else {
              router.push({ path: '/stamp' })
              this.showLayout = true
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
