<template>
  <div class="divContain my-15">
    <div class="divBackground">
      <div class="divTitle">
        <h1>ohayo login</h1>
      </div>

      <div class="text-sm-body-7 text-xl-h3 divTitleSub">Active Directoryアカウントでログイン</div>
      <div class="text-sm-body-7 text-xl-h3 divTitleSub"></div>
      <div class="divContent">
        <v-form ref="form" v-model="valid" lazy-validation class="text-center" :readonly="loading">
          <v-text-field v-model="mail" :rules="[rules.required]" label="ID" required />
          <v-text-field v-model="password" :rules="[rules.required]" label="PASS" :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'" :type="showpwd ? 'text' : 'password'" required @click:append="showpwd = !showpwd" />
          <v-tooltip top>
            <template #activator="{ on, tooltip }">
              <v-btn id="btnLogin" :loading="loading" :disabled="!valid" v-bind="tooltip" elevation="3" width="100%" color="#3f51b5" class="mb-2 text-h3 white-text" v-on="on" @click="onSubmitLogin"> LOGIN </v-btn>
            </template>
            <span>Login</span>
          </v-tooltip>
          <!-- <ErrorMessage /> -->
          <!-- <SuccessMessage /> -->
        </v-form>
      </div>
    </div>
    <verify-code v-model="showVerifyCode" persistent :mail="mail" />
    <v-dialog v-if="showPushAlert" v-model="showPushAlert" persistent max-width="500">
      <v-card>
        <v-card-text class="text-center pt-5 font-weight-bold"> 通知が OFF になっています。ブラウザの通知設定から通知を許可してください。 </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="blue darken-1" @click="showPushAlert = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VerifyCode from '../../views/verifycode/VerifyCode.vue'
import cnst from '@/const/const'
import firebaseMessaging from '../../firebase'
import router from '@/router'
import 'firebase/firebase-messaging'
export default {
  name: 'FormLogin',
  data() {
    return {
      loader: null,
      loading: false,
      valid: false,
      showVerifyCode: false,
      showSendMail: false,
      mail: '',
      password: '',
      showpwd: false,
      showPushAlert: false,
      rules: {
        required: (value) => !!value || '必須入力です',
      },
    }
  },
  components: {
    VerifyCode,
  },

  computed: {},
  methods: {
    ...mapActions('login', ['userLogin', 'authenticate']),
    async onSubmitLogin() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true

      console.log('LOGIN PROCESS API')
      const input = {
        id: this.mail,
        password: this.password,
      }

      // if (Notification.permission === 'default' || Notification.permission === 'denied') {
      //   this.showPushAlert = true
      //   this.loading = false
      //   return
      // }

      await this.getDeviceToken()

      this.authenticate(input)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            this.mail = res.data.mail
            window.localStorage.setItem(cnst.STORAGE_EMAIL, res.data.mail)
            this.onSignin()
          }
        })
        .catch((err) => {
          console.log(err)
          this.loader = null
          this.loading = false
        })
    },
    async onSignin() {
      const input = {
        mail: this.mail,
        device_token: !!window.localStorage.getItem(cnst.STORAGE_DEVICE_TOKEN) ? window.localStorage.getItem(cnst.STORAGE_DEVICE_TOKEN) : '',
        auth_token: !!localStorage.getItem(cnst.STORAGE_AUTHTOKEN) ? localStorage.getItem(cnst.STORAGE_AUTHTOKEN) : '',
      }

      await this.userLogin(input)
        .then(async (res2) => {
          if (res2.status === 200) {
            if (res2.data.token_flag) {
              localStorage.setItem(cnst.STORAGE_EMAIL, input.mail)
              router.push({ path: '/stamp' })
            } else {
              this.showVerifyCode = true
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.value = false
          this.loading = false
        })
    },
    async getDeviceToken() {
      if (firebaseMessaging.messaging.isSupported()) {
        await firebaseMessaging
          .messaging()
          .getToken({ vapidKey: 'BO3M4KAqmM0mw7y91FH1hsgELR7vBAfy33jybYA6SkVgZrEbKmhqFYIyzDV3oC-oeLPnyqF6H_lbwbNSZzF6zu8' })
          .then((currentToken) => {
            if (currentToken) {
              window.localStorage.setItem(cnst.STORAGE_DEVICE_TOKEN, currentToken)
            } else {
              console.log('No registration token available. Request permission to generate one.')
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
          })
      }
    },
  },
}
</script>

<style>
.divContain {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divContent {
  width: 95%;
  margin: auto;
}
.divBackground {
  width: 100%;
}
.divTitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16pt;
  line-height: 60px;
  color: #ffde7b;
  font-weight: 700;
}

.divTitleSub {
  text-align: center;
  color: black;
  font-weight: 500;
}
.white-text {
  color: white !important;
}
</style>
